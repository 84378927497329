<template>
  <material-chart-card
    id="DashboardCharts"
    :color="charts[0].color"
    :data="charts[0].data"
    :options="charts[0].options"
    :responsive-options="charts[0].responsiveOptions"
    :type="charts[0].type"
  >
    <template #subtitle style="">
      <v-select
        prepend-icon="mdi-newspaper-variant-outline"
        style="important; width: 50%"
        v-model="tipoDeReporteSeleccionado"
        :hint="`${tipoDeReporteSeleccionado.title}, ${tipoDeReporteSeleccionado.value}`"
        :items="tiposDeReporte"
        item-text="title"
        item-value="value"
        label="Selecciónar reporte de barras"
        persistent-hint
        return-object
        single-line
      ></v-select>
    </template>

    <template #actions>
      <!-- <v-icon class="mr-1" small> mdi-clock-outline </v-icon>

      <span
        class="text-caption grey--text font-weight-light"
        v-text="charts[0].time"
      /> -->
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            label="Mostrar datos durante las fechas de:"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dates" range scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.dialog.save(date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </template>
  </material-chart-card>
</template>
<script>
import Chartist from 'chartist';
import chartist_tooltip from 'chartist-plugin-tooltip';

export default {
  mounted() {
    (this.charts[0].data.labels = ['pedro (P123ABC)', 'juan (P234GIC)']),
      (this.charts[0].data.series = [
        [
          { meta: '(%) (700/700)', value: 50, after: '%' }, //al credito pendiente
          { meta: '(%) (67/100)', value: 20 }, //al credito pendiente
        ],
      ]);
  },
  name: 'GraficaDeBarras',
  components: {},
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
  },
  data: () => ({
    /**lstado, dropdwon de graficas */
    tiposDeReporte: [
      { title: 'Por piloto', value: 1 },
      { title: 'Por departamento', value: 2 },
    ],
    tipoDeReporteSeleccionado: { title: 'Por piloto', value: 1 },
    dates: ['2019-09-10', '2019-09-20'],
    date: new Date().toISOString().substr(0, 10),
    modal: false,
    charts: [
      {
        type: 'Bar',
        color: 'green lighten-5',
        title: 'Entregas por piloto',
        subtitle: 'Porcentaje de pedidos entregados por piloto.',
        time: 'updated 10 minutes ago',
        data: {
          labels: ['Irwin (P123ABC)', 'Mauricio (P234GIC)'],
          series: [
            [
              { meta: '(%) (700/700)', value: 100, after: '%' }, //al credito pendiente
              { meta: '(%) (67/100)', value: 67 }, //al credito pendiente
            ],
          ],
        },
        options: {
          horizontalBars: true,
          onlyInteger: true,
          plugins: [
            Chartist.plugins.tooltip(),
            chartist_tooltip({ anchorToPoint: true, appendToBody: true }),
          ],
        },
        responsiveOptions: [
          [
            'screen and (min-width: 1041px)',
            {
              onlyInteger: true,
              reverseData: false,
              horizontalBars: true,
              axisY: {
                onlyInteger: true,
                offset: 68,
              },
              axisX: {
                onlyInteger: true,
                labelInterpolationFnc: function (value) {
                  return Math.floor(value);
                },
              },
              //la distancia "disponible" que hay entre barras
              seriesBarDistance: 15,
            },
          ],
          [
            'screen and (max-width: 1040px)',
            {
              horizontalBars: false,
              onlyInteger: true,
              seriesBarDistance: 5,
              axisX: {},
              axisy: {
                labelInterpolationFnc: function (value) {
                  console.log(value);
                  return value.slice(0, 10);
                },
              },
            },
          ],
        ],
      },
    ],
  }),
};
</script>
<style>
.ct-chart {
  position: relative;
}

.ct-tooltip {
  border-radius: 15px;
  position: absolute;
  top: 15px;
  min-width: 5em;
  padding: 8px 10px;
  background-color: #000000a6;
  color: #fff;
  top: -50px;
  text-align: center;
  pointer-events: none;
  z-index: 9999999;
  transition: opacity 0.2s linear;
  word-break: keep-all !important;
}

.ct-tooltip:before {
  position: absolute;
  z-index: 9999999;
  bottom: -14px;
  left: 50%;

  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: -8px;
}

.ct-tooltip.hide {
  display: block;
  opacity: 0;
  visibility: hidden;
}
.chartist-tooltip.tooltip-show {
  display: inline-block !important;
}
.ct-label {
  font-size: 9px !important;
  word-break: break-word !important;
  color: #000000a6 !important;
  font-weight: 700 !important;
}
.ct-label:hover {
  font-size: 11px !important;
  cursor: none !important;
}
</style>
<style>
#DashboardCharts .ct-series-a .ct-bar:nth-child(1),
#DashboardCharts .ct-series-a .ct-line:nth-child(1),
.v-card--material-chart
  .v-card--material__sheet
  .ct-series-a:nth-child(1)
  .ct-point {
  stroke: #71a28a !important;
}

#DashboardCharts .ct-series-a .ct-bar:nth-child(2),
#DashboardCharts .ct-series-a .ct-line:nth-child(2),
.v-card--material-chart
  .v-card--material__sheet
  .ct-series-a:nth-child(2)
  .ct-point {
  stroke: #ff6414 !important;
}

#DashboardCharts .ct-series-a .ct-bar:nth-child(3),
#DashboardCharts .ct-series-a .ct-line:nth-child(3),
.v-card--material-chart
  .v-card--material__sheet
  .ct-series-a:nth-child(3)
  .ct-point {
  stroke: #efb200 !important;
}

#DashboardCharts .ct-series-a .ct-bar:nth-child(4),
#DashboardCharts .ct-series-a .ct-line:nth-child(4),
.v-card--material-chart
  .v-card--material__sheet
  .ct-series-a:nth-child(4)
  .ct-point {
  stroke: #91a453 !important;
}
</style>
